import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import Home from "../Pages/Home";
import Category from "../Pages/category";
import Shatranj from "../Pages/Shatranj";
import Kesh from "../Pages/Kesh";
import KamarBand from "../Pages/KamarBand";
import Navbar from "../Components/Navbar";
import MochBand from "../Pages/MochBand";
import ZanuBand from "../Pages/ZanuBand";
import All from "../Pages/all";
import ProductDetails from "../Pages/ProductDetails";
import Blog from "../Pages/Blogs/Blog";
import Blogs from "../Pages/Blogs/Blogs";
import { HelmetProvider } from 'react-helmet-async';


const Layouts = () => {
  return (
    <>
      <div className='layout min-h-[100vh]' dir="rtl">
        <Navbar />
        <HelmetProvider>
          <Routes>
            <Route path="/home" element={<Navigate to="/" />} />
            <Route path="/" element={<Home />} />
            <Route path="/category" element={<Category />} />
            <Route path="/shatranj" element={<Shatranj />} />
            <Route path="/kesh" element={<Kesh />} />
            <Route path="/kamarband" element={<KamarBand />} />
            <Route path="/mochband" element={<MochBand />} />
            <Route path="/zanuband" element={<ZanuBand />} />
            <Route path="/All" element={<All />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<Blog />} />
          </Routes>
        </HelmetProvider>
       
      </div>
    </>
  );
};

export default Layouts;
