import React from 'react';
import { Link } from 'react-router-dom';
import blogs from "../../blogsData";

const Blogs = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-yellow-200 px-6 py-3 text-gray-800 text-center">
        <h1 className="text-3xl font-thin ">عناوین بلاگ</h1>
      </header>

      <main className="container mx-auto p-6">
        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {blogs.map(item => (
            <div key={item.id} className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
              <img src={item.img} alt={item.title} className="w-full h-48 object-cover rounded-md mb-4" />
              <h2 className="text-2xl font-bold mb-2 text-blue-600">
                <Link to={`/blog/${item.id}`} className="hover:underline">{item.title}</Link>
              </h2>
              <p className="text-gray-700 mb-4">{item.desc}</p>
              <Link to={`/blog/${item.id}`} className="text-blue-500 hover:underline">ادامه مطلب...</Link>
            </div>
          ))}
        </div>
      </main>

    </div>
  );
};

export default Blogs;
