import React from "react";
const Home = () => {
  return (
    <>
      <div className="home flex justify-center w-full max-sm:block ">

      </div>
        </>
  );
};

export default Home;
