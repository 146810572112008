import ping_pong from "./assets/ping-pong.jpg";
import chess from "./assets/chess.jpg";
import kesh from "./assets/kesh.jpg";
import KamarBand from "./assets/kamarBand.jpg";
import Blog5 from "./assets/blog-5.png";
const blogs = [
 {
  id: 1,
  title: "تنیس روی میز: ورزشی با قدمت و هیجان",
  desc: "تنیس روی میز، که گاهی با نام پینگ‌پنگ شناخته می‌شود، ورزشی است که هم به عنوان سرگرمی و هم در سطح رقابتی محبوبیت دارد. این بازی که ریشه در انگلستان دارد، امروزه به یکی از ورزش‌های المپیکی تبدیل شده است.",
  qustion: "چرا تجهیزات با کیفیت برای تنیس روی میز مهم است؟",
  "descq": "تجهیزات با کیفیت، نه تنها عملکرد بازیکنان را بهبود می‌بخشد بلکه از آسیب‌دیدگی آن‌ها نیز جلوگیری می‌کندتجهیزات ورزشی تی اس تی، با ارائه محصولاتی متنوع و با دوام، تجربه‌ای لذت‌بخش و ایمن را برای بازیکنان فراهم می‌آورد.",
  title2: "راکت تنیس TST: انتخاب حرفه‌ای‌ها",
  desc2: "راکت‌های تنیس روی میز TST با طراحی ارگونومیک و مواد با کیفیت، به بازیکنان کمک می‌کنند تا با دقت و قدرت بیشتری به توپ ضربه بزنند. این راکت‌ها برای هر سطحی از بازی، از مبتدی تا حرفه‌ای، مناسب هستند.",
  qustion2: "چگونه می‌توان تجهیزات تنیس روی میز را نگهداری کرد؟",
  descq2: "نگهداری صحیح از تجهیزات تنیس روی میز می‌تواند عمر مفید آن‌ها را افزایش دهد. تمیز کردن منظم راکت‌ها و میز، و نگهداری توپ‌ها در محیطی خشک و دور از نور مستقیم خورشید، از اقدامات مهم در این زمینه است.",
  result: "تنیس روی میز، با قدمتی که به قرن‌ها پیش بازمی‌گردد، همچنان یکی از جذاب‌ترین ورزش‌ها برای تمام سنین استلوازم ورزشی TST با ارائه تجهیزاتی با کیفیت و متنوع، به بازیکنان کمک می‌کند تا از این ورزش لذت ببرند و در عین حال ایمنی خود را حفظ کنند.",
  cq: "آیا تنیس روی میز فقط یک ورزش سرگرمی است؟خیر، تنیس روی میز هم به صورت رقابتی و هم به عنوان سرگرمی انجام می‌شود و در المپیک نیز جایگاه دارد.",
  cq2: "چه تجهیزاتی برای شروع تنیس روی میز نیاز است؟برای شروع، به یک میز استاندارد، راکت، و توپ‌های با کیفیت نیاز دارید.",
  cq3: "چگونه می‌توانم تجهیزات تنیس روی میز TST را تهیه کنم؟ می‌توانید از طریق وب‌سایت تی اس تی یا ارتباط با ما، این تجهیزات ورزشی را تهیه کنید.",
  img:ping_pong
 },
 {
  id: 2,
  title: "شطرنج: بازی استراتژیک با قدمتی دیرینه",
  desc: "شطرنج، بازی استراتژیک و فکری است که قدمت آن به ۱۵۰۰ سال پیش بازمی‌گردد. این بازی که در امپراتوری گوپتا در هند پدید آمد، به سرعت در سراسر جهان گسترش یافت و به یکی از محبوب‌ترین بازی‌های فکری تبدیل شد.",
  qustion: "تجهیزات ورزشی تی اس تی: همراهی برای شطرنج‌بازان",
  "descq": "برای انجام بازی شطرنج، نیاز به تجهیزاتی دقیق و با کیفیت استتجهیزات ورزشی تی اس تی با ارائه محصولاتی متنوع و با کیفیت، به شطرنج‌بازان کمک می‌کند تا بازی را در بهترین شرایط ممکن انجام دهند.",
  title2: "لوازم ورزشی تی اس تی: تنوع برای هر سطح از بازی",
  desc2: "لوازم ورزشی تی اس تی شامل صفحات شطرنج، مهره‌ها، ساعت‌های شطرنج و دیگر لوازم جانبی است که برای هر سطحی از بازی، از مبتدی تا حرفه‌ای، مناسب هستند.",
  title3: "لوازم TST: کیفیت در خدمت فکر",
  desc3: "لوازم TST با تأکید بر کیفیت و طراحی منحصر به فرد، تجربه‌ای لذت‌بخش و فکری را برای شطرنج‌بازان فراهم می‌آورد. این تجهیزات به بازیکنان اجازه می‌دهند تا بر استراتژی‌های خود تمرکز کنند.",
  title4: "انواع لوازم ورزشی TST: برای هر نوع بازی",
  desc4: "چه در مسابقات رسمی و چه در بازی‌های دوستانه، انواع لوازم ورزشی TST پاسخگوی نیازهای شطرنج‌بازان در هر سطحی از مهارت است. این تجهیزات با دوام و انعطاف‌پذیری بالا، به بازیکنان کمک می‌کنند تا در هر مرحله‌ای از یادگیری و پیشرفت، بهترین عملکرد را داشته باشند.",
  title5: "شطرنجTST: نوآوری در بازی",
  desc5: "شطرنجTST با ارائه محصولات نوآورانه و طراحی‌های خلاقانه، به شطرنج‌بازان این امکان را می‌دهد که تجربه‌ای نوین از بازی شطرنج داشته باشند.",
  qustion2: "",
  descq2: "",
  result: "شطرنج، با قدمتی دیرینه و جایگاهی مهم در میان بازی‌های فکری، همچنان به عنوان یکی از محبوب‌ترین بازی‌ها در سراسر جهان شناخته می‌شود. تجهیزات ورزشی تی اس تی، با ارائه محصولاتی متنوع و با کیفیت، به شطرنج‌بازان کمک می‌کند تا این بازی استراتژیک را با تجربه‌ای ایمن و به‌روز انجام دهند.",
  cq: "چرا انتخاب تجهیزات با کیفیت برای شطرنج مهم است؟ انتخاب تجهیزات با کیفیت به بازیکنان کمک می‌کند تا تمرکز بیشتری بر روی استراتژی‌ها و تاکتیک‌های بازی داشته باشند.",
  cq2: "آیا تجهیزات شطرنج برای همه سطوح مناسب است؟بله، تجهیزات شطرنج در انواع و سایزهای مختلفی موجود است که برای تمام سطوح از مبتدی تا حرفه‌ای مناسب است.",
  cq3: "",
  img:chess
 },
 {
  id: 3,
  title: "تنیس روی میز: ورزشی با قدمت و هیجان",
  desc: "تنیس روی میز، که گاهی با نام پینگ‌پنگ شناخته می‌شود، ورزشی است که هم به عنوان سرگرمی و هم در سطح رقابتی محبوبیت دارد. این بازی که ریشه در انگلستان دارد، امروزه به یکی از ورزش‌های المپیکی تبدیل شده است.",
  qustion: "چرا تجهیزات با کیفیت برای تنیس روی میز مهم است؟",
  "descq": "تجهیزات با کیفیت، نه تنها عملکرد بازیکنان را بهبود می‌بخشد بلکه از آسیب‌دیدگی آن‌ها نیز جلوگیری می‌کندتجهیزات ورزشی تی اس تی، با ارائه محصولاتی متنوع و با دوام، تجربه‌ای لذت‌بخش و ایمن را برای بازیکنان فراهم می‌آورد.",
  title2: "راکت تنیس TST: انتخاب حرفه‌ای‌ها",
  desc2: "راکت‌های تنیس روی میز TST با طراحی ارگونومیک و مواد با کیفیت، به بازیکنان کمک می‌کنند تا با دقت و قدرت بیشتری به توپ ضربه بزنند. این راکت‌ها برای هر سطحی از بازی، از مبتدی تا حرفه‌ای، مناسب هستند.",
  qustion2: "چگونه می‌توان تجهیزات تنیس روی میز را نگهداری کرد؟",
  descq2: "نگهداری صحیح از تجهیزات تنیس روی میز می‌تواند عمر مفید آن‌ها را افزایش دهد. تمیز کردن منظم راکت‌ها و میز، و نگهداری توپ‌ها در محیطی خشک و دور از نور مستقیم خورشید، از اقدامات مهم در این زمینه است.",
  result: "تنیس روی میز، با قدمتی که به قرن‌ها پیش بازمی‌گردد، همچنان یکی از جذاب‌ترین ورزش‌ها برای تمام سنین استلوازم ورزشی TST با ارائه تجهیزاتی با کیفیت و متنوع، به بازیکنان کمک می‌کند تا از این ورزش لذت ببرند و در عین حال ایمنی خود را حفظ کنند.",
  cq: "یا تنیس روی میز فقط یک ورزش سرگرمی است؟خیر، تنیس روی میز هم به صورت رقابتی و هم به عنوان سرگرمی انجام می‌شود و در المپیک نیز جایگاه دارد.",
  cq2: "چه تجهیزاتی برای شروع تنیس روی میز نیاز است؟برای شروع، به یک میز استاندارد، راکت، و توپ‌های با کیفیت نیاز دارید.",
  cq3: "چگونه می‌توانم تجهیزات تنیس روی میز TST را تهیه کنم؟می‌توانید از طریق وب‌سایت تی اس تی یا ارتباط با ما، این تجهیزات ورزشی را تهیه کنید.",
  img:ping_pong
 },
 {
  id: 4,
  title: "بند لیفت ورزشی: افزایش قدرت و ایمنی در بدنسازی",
  desc: "ند لیفت ورزشی، یکی از تجهیزات کلیدی در بدنسازی است که به ورزشکاران کمک می‌کند تا با افزایش توان و قدرت دستان، کنترل و تمرکز کافی روی حرکت مچ و دست داشته باشند. بند لیفت ورزشی تی اس تی با کیفیت بالا و طراحی ارگونومیک، انتخابی عالی برای ورزشکارانی است که به دنبال بهبود عملکرد و افزایش ایمنی هستند.",
  qustion: "چرا باید از بند لیفت TST استفاده کنیم؟",
  "descq": "استفاده از بند لیفت TST به ورزشکاران این امکان را می‌دهد که در حین انجام حرکات سنگین مانند بارفیکس و بلند کردن وزنه، فشار کمتری به مچ دست وارد شود. این کاهش فشار به پیشگیری از آسیب‌دیدگی کمک کرده و به ورزشکار اجازه می‌دهد تا تمرکز بیشتری بر روی عضلات هدف داشته باشد.",
  title2: "لوازم ورزشی TST: تنوع و کیفیت",
  desc2: "لوازم ورزشی TST شامل انواع بند لیفت با مواد مختلف مانند کتان، چرم و مواد مصنوعی است که هر کدام ویژگی‌های منحصر به فردی دارند. این تنوع اطمینان می‌دهد که هر ورزشکار بتواند بند لیفتی را انتخاب کند که به بهترین شکل با نیازهای او مطابقت داشته باشد.",
  qustion2: "",
  descq2: "",
  result: "بند لیفت ورزشی یک ابزار حیاتی برای ورزشکارانی است که به دنبال افزایش عملکرد و کاهش خطر آسیب‌دیدگی هستند. تجهیزات ورزشی تی اس تی با ارائه انواع بند لیفت با کیفیت بالا، به ورزشکاران کمک می‌کند تا با اطمینان و امنیت بیشتری تمرین کنند. انتخاب بند لیفت مناسب، گامی مهم در جهت دستیابی به اهداف ورزشی و حفظ سلامتی است.",
  cq: "آیا استفاده از بند لیفت در تمامی تمرینات بدنسازی توصیه می‌شود؟خیر، استفاده از بند لیفت بیشتر در تمریناتی که فشار زیادی به مچ دست وارد می‌کنند، مانند ددلیفت و روئینگ توصیه می‌شود.",
  cq2: "چگونه می‌توان بند لیفت مناسب را انتخاب کرد؟بند لیفت مناسب باید از مواد با دوام ساخته شده و به خوبی به دور مچ بپیچد تا حمایت کافی را فراهم کند. همچنین باید از نظر اندازه و راحتی با دست شما مطابقت داشته باشد.",
  cq3: "چه موقع باید بند لیفت را تعویض کرد؟ زمانی که بند لیفت شروع به فرسودگی می‌کند یا دیگر حمایت کافی را ارائه نمی‌دهد، باید آن را تعویض کرد.",
  img:kesh
 },
 {
  id: 5,
  title: "کمربند بدنسازی TST: افزایش ایمنی و کارایی در تمرینات",
  desc: "کمربند بدنسازی، یکی از ابزارهای مهم برای ورزشکارانی است که به دنبال افزایش عملکرد و حفظ ایمنی در حین انجام تمرینات سنگین هستند. کمربند بدنسازی تی اس تی با طراحی ارگونومیک و مواد با کیفیت، حمایت لازم را به کمر و کمک به حفظ فرم صحیح بدن در حین بلند کردن وزنه‌ها می‌دهد.",
  qustion: "چرا استفاده از کمربند بدن سازی TST در تمرینات مهم است؟",
  "descq": "ستفاده از کمربند بدن سازی TST می‌تواند به کاهش فشار وارد بر کمر کمک کرده و از آسیب‌دیدگی‌های احتمالی جلوگیری کند. همچنین، این کمربندها به ورزشکاران کمک می‌کنند تا تمرکز بیشتری بر روی عضلات هدف داشته باشند و از پرش کمر در حین انجام حرکات جلوگیری کنند.",
  title2: "کمر بند بدن سازی تی اس تی: تنوع برای هر سبک تمرینی",
  desc2: "کمر بند بدن سازی تی اس تی در انواع مختلفی از جنس‌ها مانند چرم و نئوپرن موجود است تا به هر ورزشکاری امکان دهد کمربندی را انتخاب کند که با نوع تمرین و سبک شخصی او مطابقت داشته باشد.",
  qustion2: "",
  descq2: "",
  result: "کمربند بدنسازی یک ابزار حیاتی برای ورزشکارانی است که به دنبال افزایش عملکرد و کاهش خطر آسیب‌دیدگی هستند. کمربند بدنسازی TST با ارائه انواع کمربندها با کیفیت بالا، به ورزشکاران کمک می‌کند تا با اطمینان و امنیت بیشتری تمرین کنند. انتخاب کمربند مناسب، گامی مهم در جهت دستیابی به اهداف ورزشی و حفظ سلامتی است.",
  cq: "آیا کمربند بدنسازی فقط برای حرفه‌ای‌ها است؟خیر، استفاده از کمربند بدنسازی برای هر کسی که تمرینات با وزنه‌های سنگین انجام می‌دهد، توصیه می‌شود، چه مبتدی باشد و چه حرفه‌ای.",
  cq2:"چگونه می‌توان کمربند بدنسازی مناسب را انتخاب کرد؟کمربند بدنسازی مناسب باید از جنس با دوام ساخته شده و به خوبی به دور کمر بپیچد تا حمایت کافی را فراهم کند. همچنین باید از نظر اندازه و راحتی با بدن شما مطابقت داشته باشد.",
  cq3:"چه موقع باید کمربند بدنسازی را تعویض کرد؟زمانی که کمربند شروع به فرسودگی می‌کند یا دیگر حمایت کافی را ارائه نمی‌دهد، باید آن را تعویض کرد.",
  img:KamarBand
 },
 {
  id: 6,
  title: "قمقمه ورزشی TST: همراهی ضروری برای هر ورزشکار",
  desc: "هنگامی که صحبت از تجهیزات ورزشی می‌شود، قمقمه‌های ورزشی شاید به اندازه کفش‌های دویدن یا دستکش‌های بدنسازی به چشم نیایند، اما اهمیت آن‌ها در حفظ سلامتی و عملکرد ورزشکاران بی‌بدیل است. قمقمه ورزشی تی اس تی با طراحی مدرن و کاربردی، نیازهای ورزشکاران را در هر شرایطی پاسخ می‌دهد.",
  qustion: "چرا انتخاب قمقمه ورزشی مناسب مهم است؟",
  "descq": "یک قمقمه تی اس تی خوب باید ویژگی‌هایی مانند دوام، سهولت استفاده و نگهداری، و توانایی حفظ دمای نوشیدنی را داشته باشد. این ویژگی‌ها به ورزشکاران کمک می‌کند تا هیدراته بمانند و عملکرد بهتری داشته باشند.",
  title2: "انواع قمقمه ورزشی TST: گزینه‌های متنوع برای هر سلیقه",
  desc2: "انواع قمقمه ورزشی TST شامل مدل‌های مختلف با اندازه‌ها و رنگ‌های گوناگون است تا هر فرد بتواند بر اساس نیاز و سلیقه خود بهترین انتخاب را داشته باشد. از قمقمه‌های فشاری گرفته تا مدل‌هایی با درپوش‌های هوشمند، هر کدام ویژگی‌های منحصر به فردی دارند.",
  qustion2: "",
  descq2: "",
  result: "قمقمه ورزشی یک ابزار ضروری برای هر ورزشکاری است که به دنبال حفظ سلامتی و عملکرد بالا در طول تمرینات است. قمقمه ورزشی تی اس تی با ارائه انواع مختلف و با کیفیت بالا، به ورزشکاران کمک می‌کند تا هیدراته بمانند و از فعالیت‌های خود لذت ببرند. انتخاب قمقمه مناسب، گامی مهم در جهت دستیابی به اهداف ورزشی و حفظ سلامتی است.",
  cq: "1. آیا استفاده از قمقمه‌های ورزشی فقط برای باشگاه‌ها است؟خیر، استفاده از قمقمه‌های ورزشی TST در هر فعالیت بدنی، چه در باشگاه و چه در فضای باز، برای حفظ هیدراتاسیون ضروری است.",
  cq2:"2. چگونه می‌توان قمقمه ورزشی را تمیز نگه داشت؟تمیز کردن منظم با آب گرم و مواد شوینده ملایم، و خشک کردن کامل پس از شستشو، به حفظ بهداشت و طول عمر قمقمه TST کمک می‌کند",
  cq3:"3. چه زمانی باید قمقمه ورزشی خود را تعویض کنیم؟زمانی که قمقمه شروع به نشت کردن یا بوی نامطبوع گرفتن می‌کند، یا اگر دیگر قادر به حفظ دمای نوشیدنی نیست، باید تعویض شود.",
  img:Blog5
 }
]


export default blogs