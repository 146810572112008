// import React from 'react';
// import { useParams } from 'react-router-dom';
// import tstData from '../tstData';

// const ProductDetails = () => {
//   const { id } = useParams();
//   const product = tstData.find(item => item.id.toString() === id);

//   if (!product) {
//     return <h2>محصول مورد نظر یافت نشد</h2>;
//   }

//   return (
//     <div className="product-details mx-32 max-sm:mx-5 max-xl:mx-10 mt-28">
//       <div className="flex mt-6">
//         <img src={product.img} className="my-8 w-[460px] h-[450px] object-contain" alt={product.name} />
//       </div>
//       <h1 className="text-right text-4xl text-gray-600">{product.name}</h1>
//       <div className="text-right text-gray-600 text-xl mt-4">
//         <p>{product.desc}</p>
//         <div className="flex">
//         <p className='ml-5'>طول کش :{product.length}</p>
//         <p>  قدرت{product.power}</p>
//         </div>
//         {/* <p>قیمت: {product.price}</p> */}
//       </div>
//     </div>
//   );
// };

// export default ProductDetails;
import React from "react";
import { useParams } from "react-router-dom";
import tstData from "../tstData";
import { Link } from "react-router-dom";

const ProductDetails = () => {
  const { id } = useParams();
  const product = tstData.find((item) => item.id.toString() === id);

  if (!product) {
    return <h2>محصول مورد نظر یافت نشد</h2>;
  }

  const productIndex = tstData.findIndex((item) => item.id.toString() === id);
  const previousProducts = tstData.slice(
    Math.max(productIndex - 2, 0),
    productIndex
  );
  const nextProducts = tstData.slice(
    productIndex + 1,
    Math.min(productIndex + 3, tstData.length)
  );

  return (
    <div className="product-details mx-5 max-lg:mx-2 mt-28 flex flex-col lg:flex-row lg:justify-between">
      <div className="main-content flex-1">
        <div className="flex mt-6">
          <img
            src={product.img}
            className="mb-8 w-[460px] h-[450px] object-contain"
            alt={product.name}
          />
        </div>
        <h1 className="text-right text-4xl text-gray-600">{product.name}</h1>
        <div className="text-right text-gray-600 text-xl mt-4">
          <p>{product.desc}</p>
          <div className="flex">
            <p className="ml-5">طول کش :{product.length}</p>
            <p> قدرت{product.power}</p>
          </div>
          {/* <p>قیمت: {product.price}</p> */}
        </div>
      </div>
      <div className="sidebar w-full lg:w-1/4 lg:ml-10 mt-10 lg:mt-0">
        <h2 className="text-2xl text-gray-600 mb-4">محصولات مرتبط</h2>
        <div className="related-products">
          {previousProducts.map((item) => (
            <Link
              to={`/product/${item.id}`}
              key={item.id}
              className="block mb-4"
            >
              <div className="flex items-center">
                <img
                  src={item.img}
                  className="w-16 h-16 object-contain mr-2"
                  alt={item.name}
                />
                <span className="text-gray-600">{item.name}</span>
              </div>
            </Link>
          ))}
          {nextProducts.map((item) => (
            <Link
              to={`/product/${item.id}`}
              key={item.id}
              className="block mb-4"
            >
              <div className="flex items-center">
                <img
                  src={item.img}
                  className="w-16 h-16 object-contain mr-2"
                  alt={item.name}
                />
                <span className="text-gray-600">{item.name}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
