import { configureStore } from '@reduxjs/toolkit';
import plantsReducer from './tstData';

const store = configureStore({
  reducer: {
    plants: plantsReducer,
  },
});

export default store;
