// import React from "react";
// import { Link } from "react-router-dom";
// import Shatranj from "../assets/shatranj.jpg";
// import KamarBand from "../assets/kamarBand.jpg";
// import moch from "../assets/moch.jpg";
// import kesh from "../assets/kesh.jpg";
// import zanu from "../assets/zanu.jpg";
// const sectionsData = [
//   { id: 1, title: "سکشن 1", img:  Shatranj , link: "/page1" },
//   { id: 2, title: "سکشن 2", img: KamarBand , link: "/page2" },
//   { id: 3, title: "سکشن 3", img: moch , link: "/page3" },
//   { id: 4, title: "سکشن 4", img: kesh, link: "/page4" },
//   { id: 5, title: "سکشن 5", img: zanu , link: "/page5" },
// ];const Category = () => {
//   return (
//     <div className="category">
//       <div className=" mx-10 p-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 pt-52  gap-5">
//         {sectionsData.map((section) => (
//           <div
//             key={section.id}
//             className="relative overflow-hidden rounded-lg shadow-lg transition transform hover:scale-105 duration-500"
//           >
//             <img
//               src={section.img}
//               alt={section.title}
//               className="w-full h-86 object-cover"
//             />
//             <div className="absolute inset-0 bg-opacity-50 flex items-center justify-center">
//               <h2 className="text-white text-xl bg-grayx  w-full font-bold">{section.title}</h2>
//             </div>
//             <Link
//               to={section.link}
//               className="absolute inset-0 flex items-center justify-center"
//             >
//               <span className="sr-only">{section.title}</span>
//             </Link>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Category;
import React from 'react';
import { Link } from 'react-router-dom';
import Shatranj from "../assets/shatranj.jpg";
import KamarBand from "../assets/kamarBand.jpg";
import moch from "../assets/moch.jpg";
import kesh from "../assets/kesh.jpg";
import zanu from "../assets/zanu.jpg";

const sectionsData = [
  { id: 1, title: "شطرنج", img: Shatranj, link: "/Shatranj" },
  { id: 2, title: "کمربند", img: KamarBand, link: "/KamarBand" },
  { id: 3, title: "مچ بند ورزشی", img: moch, link: "/mochband" },
  { id: 4, title: "کش ورزشی", img: kesh, link: "/kesh" },
  { id: 5, title: "زانوبند", img: zanu, link: "/zanuband" },
];

const Sections = () => {
  return (
    <div className="category">
      <div className="mx-10 p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5  pt-52 max-md:pt-12 gap-5 max-h-full overflow-y-auto">
        {sectionsData.map((section) => (
          <div
            key={section.id}
            className="parent relative overflow-hidden rounded-lg shadow-lg transition transform hover:scale-105 duration-500"
          >
            <img
              src={section.img}
              alt={section.title}
              className="w-full h-86 object-cover"
            />
            <div className="absolute inset-0 bg-opacity-50 flex items-center justify-center">
              <h2 className="hover-show text-gray-600 text-lg font-thin">{section.title}</h2>
            </div>
            <Link
              to={section.link}
              className="absolute inset-0 flex items-center justify-center"
            >
              <span className="sr-only">{section.title}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sections;
