import product_1 from "./images/product-1.jpg"
import product_2 from "./images/product-2.jpg"
import product_3 from "./images/product-3.jpg"
import product_4 from "./images/product-4.jpg"
import product_5 from "./images/product-5.jpg"
import product_6 from "./images/product-6.jpg"
import product_7 from "./images/product-7.jpg"
import product_8 from "./images/product-8.jpg"
import product_9 from "./images/product-9.jpg"
import product_10 from "./images/product-10.jpg"
import product_11 from "./images/product-11.jpg"
import product_12 from "./images/product-12.jpg"
import product_13 from "./images/product-13.jpg"
import product_14 from "./images/product-14.jpg"
import product_15 from "./images/product-15.jpg"



const tstData = [
 {
  id: 1,
  name: "کش بدنسازی TST LEVEL 1",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: "2 متر ",
  power: "متوسط (همگانی)",
  img: product_1

 },
 {
  id: 2,
  name: "کش بدنسازی TST LEVEL 2",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: " 2.5 متر ",
  power: "متوسط ( همگانی ، بانوان )",
  img: product_2
 },
 {
  id: 3,
  name: "کش بدنسازی TST LEVEL 3",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: " 2.5 متر ",
  power: "  : قوی ( آقایان )",
  img: product_3
 },
 {
  id: 4,
  name: "کش بدنسازی TST LEVEL 4",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: " 3.5 متر ",
  power: "قوی ( اقایان )",
  img: product_4
 },
 {
  id: 5,
  name: "کش بدنسازی TST LEVEL 5",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: " 2.5 متر",
  power: " قدرتی ",
  img: product_5
 },
 {
  id: 6,
  name: "کش بدنسازی TST LEVEL 6",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: " 3.5 متر ",
  power: " قدرتی",
  img: product_6
 },
 {
  id: 7,
  name: "کش بدنسازی TST LEVEL 7",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: "3 متر",
  power: "قدرتی",
  img: product_7
 },
 {
  id: 8,
  name: "کش بدنسازی TST LEVEL 8",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: "3.5 متر",
  power: "قدرتی",
  img: product_8
 },
 {
  id: 9,
  name: "کش بدنسازی TST LEVEL 9",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: "3.5 متر",
  power: "سنگین",
  img: product_9
 },
 {
  id: 10,
  name: "کش کشتی TST",
  category: "کش",
  desc: "دسته ها با قابلیت اتصال به هم و تبدیل شدن به شکم بند و اتصال به دست و پا می باشد .",
  length: "6 متر",
  power: "فوق سنگین",
  img: product_10
 },
 {
  id: 11,
  name: "کش بدنسازی TST+ level 1",
  category: "کش",
  desc: "قابلیت تنظیم طول کش تسمه دست و پا ، تسمه کمربندی لاتکس لوله ای",
  length: " ۲.۵ متر  ",
  power: "متوسط",
  img: product_11
 },
 {
  id: 12,
  name: "کش بدنسازی TST+ level 2",
  category: "کش",
  desc: "قابلیت تنظیم طول کش تسمه دست و پا ، تسمه کمربندی لاتکس لوله ای",
  length: "2.5 متر",
  power: "قوی",
  img: product_12
 },
 {
  id: 13,
  name: "کش بدنسازی TST+ level 3",
  category: "کش",
  desc: "قابلیت تنظیم طول کش تسمه دست و پا ، تسمه کمربندی لاتکس لوله ای",
  length: "2.5 متر",
  power: "قدرتی",
  img: product_13
 },
 {
  id: 14,
  name: "بند لیفت مچ دار TST",
  category: "مچ بند",
  desc: "",
  img: product_14
 },
 {
  id: 15,
  name: "شطرنج فدراسیونی TST",
  category: "شطرنج",
  desc: "",
  img: product_15

 },
];

export default  tstData

