import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <div className="flex justify-center bg-white">
        <ul
          class="Navbar w-full flex justify-center text-center py-8 mx-8 text-gray-500 font-light"
          dir="rtl"
        >
          <Link to="/">
            <li className="mx-2">خانه</li>
          </Link>
            <Link to="/Category">
              <li className="mx-2 dropdown">دسته بندی</li>
            </Link>
          <Link to="/all">
            <li class="mx-5">محصولات ما</li>
          </Link>
          <Link to="/Blogs">
            <li class="mx-5">بلاگ</li>
          </Link>
          <li class="mx-5">درباره ما</li>
        </ul>
      </div>
      {/* mobile menu */}
      <div className="mobileMenu">
        <div className="flex justify-between items-center text-dark-blue text-2xl">
          <div className="p-2">
            {showMenu ? (
              <FaTimes onClick={toggleMenu} />
            ) : (
              <FaBars onClick={toggleMenu} />
            )}
          </div>
        </div>
        <div
          className={`transition-all duration-500 ease-in-out ${
            showMenu ? "opacity-100 scale-100" : "opacity-0 scale-0"
          }`}
        >
          <ul
            className="w-full text-right flex justify-center text-gray-600 font-light"
            dir="rtl"
          >
            <Link to="/">
              <li className="mx-2">خانه</li>
            </Link>
            <Link to="/Category">
              <li className="mx-2 dropdown">دسته بندی</li>
            </Link>
            <Link to="/Blogs">
              <li className="mx-2">بلاگ</li>
            </Link>
            <Link to="/Blogs">
              <li className="mx-2">تماس با ما</li>
            </Link>
            <li className="mx-2">درباره ما</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
