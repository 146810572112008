// BlogDetails.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import blogs from '../../blogsData';

const Blog = () => {
  const { id } = useParams();
  const blog = blogs.find(blog => blog.id === parseInt(id));

  if (!blog) {
    return <div className="text-center text-red-500">وبلاگ مورد نظر یافت نشد.</div>;
  }

  const otherBlogs = blogs.filter(blog => blog.id !== parseInt(id));

  return (
    <div className="max-w-7xl mx-auto p-4 flex flex-col-reverse lg:flex-row">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.desc} />
      </Helmet>
      <article className="lg:w-3/4 space-y-6 order-2 lg:order-1">
        <h1 className="text-3xl font-bold text-center">{blog.title}</h1>
        <img src={blog.img} alt={blog.title} className="w-full h-auto rounded" />
        <p className="text-lg">{blog.desc}</p>
        <section>
          <h2 className="text-2xl font-semibold">{blog.qustion}</h2>
          <p>{blog.descq}</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold">{blog.title2}</h2>
          <p>{blog.desc2}</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold">{blog.qustion2}</h2>
          <p>{blog.descq2}</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold">{blog.title3}</h2>
          <p>{blog.desc3}</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold">{blog.title4}</h2>
          <p>{blog.desc4}</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold">{blog.title5}</h2>
          <p>{blog.desc5}</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold">نتیجه‌گیری</h2>
          <p>{blog.result}</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold">سوالات متداول</h2>
          <ul className="space-y-2">
            <li>{blog.cq}</li>
            <li>{blog.cq2}</li>
            <li>{blog.cq3}</li>
          </ul>
        </section>
      </article>
      <aside className="lg:w-1/4 mb-8 lg:mb-0 lg:mr-8 order-1 lg:order-2">
        <h2 className="text-2xl font-semibold">وبلاگ‌های مرتبط</h2>
        <ul className="space-y-4">
          {otherBlogs.map(blog => (
            <li key={blog.id} className="flex items-center space-x-4">
              <img src={blog.img} alt={blog.title} className="w-16 h-16 object-cover rounded" />
              <Link to={`/blog/${blog.id}`} className="text-blue-500 hover:underline">
                {blog.title}
              </Link>
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
}

export default Blog;
