import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group"; // import کتابخانه انیمیشن
import "./modal.css"
import tstData from "../tstData";
import { Link } from 'react-router-dom';


const Kesh = () => {




  return (
    <>
      <h1 className="mt-28 text-center text-4xl text-gray-600">  کش های ورزشی تی اس تی</h1>
      <div className="sections mt-6 mx-32 max-sm:mx-5 max-xl:mx-10">    {tstData.map((item, index) => (
        <div className="flex justify-center" key={index}>
          {item.category === "کش" && (
            
            <Link to={`/product/${item.id}`}>
            <div
              className="cartDiv flex mx-4 my-4 w-80 h-80 justify-center"

            >
              <div>
                <img
                  src={item.img}
                  className="w-[260px] h-[250px] top-[40px] object-contain"
                  alt=""
                />
                <h2 className="text-center text-gray-600 text-xl">
                  {item.name}
                </h2>
                
              </div>
            </div>
            </Link>
          )}
        </div>
      ))}
      </div>

    </>
  );
};

export default Kesh;
